import {ReactComponent as LogoutSvg } from "../../assets/images/user/log-out-hollow-dark.svg"
import {ReactComponent as BottomAdd } from "../../assets/images/user/add.svg"
import {ReactComponent as Not } from "../../assets/images/user/not.svg"
import {ReactComponent as Yes } from "../../assets/images/user/yes.svg"
import {ReactComponent as Copy } from "../../assets/images/user/copy-filled.svg"
import {ReactComponent as Delete } from "../../assets/images/user/trash-filled-red.svg"
import {ReactComponent as MusicFile } from "../../assets/images/musicFile.svg"
import {ReactComponent as Edict } from "../../assets/images/edit.svg"
import ESTranslation from "../../ES/ESTranslation"
import Table from "../../components/container/table"
import { LOGIN_ENDPOINTS, USER_ENDPOINTS } from "../../services/api.config"
import get from "../../helpers/get"
import { UserAuthContext } from "../../contexts/authContext"
import ModalBackground from "../../components/modal/modal_background"
import ModalSearcher from "../../components/modal/modal_new"
import useModal from "../../hooks/useModal"
import { useEffect, useRef, useState } from "react"
import useFetch from "../../hooks/useFetch"
import post from "../../helpers/post"
import put from "../../helpers/put"
// import ModalAlert from "../../components/modal/modal_alert"
import adelete from "../../helpers/adelete"
import ModalMessage from "../../components/modal/modal_music_view"
import ModalMusic from "../../components/modal/modal_music"
import ModalMusicView from "../../components/modal/modal_music_view"
import ModalAlert from "../../components/modal/modal_alert"
import { Link } from "react-router-dom"
import { USER } from "../../routes/paths"


const preprocessTableBodyData=(data,setConf,setDene,setEspe,setClients,handleDelete)=>{
    console.log(data)
    let conf = 0
    let dene = 0
    let espe = 0
    let classN = "w-fit m-auto font-bold"
    data=data || []
    const actionButtons=(id)=>{
        return(
            <Link to={USER+'/'+id} className="m-auto">
                <Edict className="w-8 h-auto m-auto fill-green stroke-green"/>
            </Link>
        )
    }
    const getStatus = (data)=>{
        if(data === 0){
            espe++
            return(
                <p className={`text-yellow ${classN}`}>En espera</p>
            )
        }
        if(data === 1){
            conf++
            return(
                <p className={`text-green ${classN}`}>Confirmado</p>
            )
        }
        if(data === 2){
            dene++
            return(
                <p className={`text-red ${classN}`}>No asistira</p>
            )
        }
    }
    const htmlNumber= (data)=>{
        return(
            <p className="w-fit h-fit m-auto">{data}</p>
        )
    }
    const htmlLink= (data)=>{
        const handleCopy = (e, link) => {
            navigator.clipboard.writeText(`https://lingydaniel.maikdevs.com/?link=${link}`)
          } 
        //   <p className="p-2 rounded-lg w-fit m-auto bg-primary-m50 cursor-pointer" onClick={(e)=>{handleCopy(e,data)}}>copiar</p>
        return(
            <Copy className="rounded-lg m-auto fill-primary cursor-pointer w-auto h-8" onClick={(e)=>{handleCopy(e,data)}}/>
            
        )
    }
    const deleteButton= (id,name)=>{
        return (
            <>
                <div className="flex justify-center">
                    <Delete className="m-auto cursor-pointer h-6 w-auto" onClick={()=>{handleDelete(id,name)}}/>
                </div>
            </>
        )
    }
    const htmlName=(message,name)=>{
        var use = false
        if(message.length > 0){
            use = true
        }
        return (
            <>
                <div className="flex justify-center">
                    <p className={use?"font-bold cursor-pointer":""}>{name}</p>
                </div>
            </>
        )
    }
    let _processedData=[...data].map(row=>{
        let {id,family,link}=row
        console.log(family)
            // let  _slug=replaceEmptySpaces
            let _newData={
                Delete : deleteButton(id,family),
                Name: htmlName('message',family),
                Link: htmlLink(link),
                // htmlNumber: htmlNumber(number),
                // Statu: getStatus(status),
                actions:actionButtons(id)
            }
            return _newData
    })
    setConf(conf)
    setDene(dene)
    setEspe(espe)
    return _processedData
        
}

const UserHome = ()=>{
    let [name, setName] = useState("")
    let [table, setTable] = useState(0)
    let [conf, setConf] = useState(0)
    let [dene, setDene] = useState(0)
    let [espe, setEspe] = useState(0)
    let [deleteName, setDeleteName] = useState()
    let [deleteId, setDeleteId] = useState()
    let [message, setMessage] = useState()
    let [messageName, setMessageName] = useState()
    let [isActiveModal,activateModal,desactivateModal]=useModal(false)
    let [isActiveDelete,activateDelete,desactivateDelete]=useModal(false)
    let [isActiveMusic,activateMusic,desactivateMusic]=useModal(false)
    let {data:fetchedClients,loading:loadingFetchedClients,error:fetchedClientsErrors}=useFetch(USER_ENDPOINTS.getAll())
    let [processedClients,setProcessedClients]=useState(null)
    let [clients,setClients]=useState(null)
    const {logout} = UserAuthContext();

    useEffect(()=>{
        if(fetchedClients)setClients(fetchedClients.data)
    },[fetchedClients])

    useEffect(()=>{
        let _processedData=preprocessTableBodyData(clients,setConf,setDene,setEspe,setClients,handleDelete)
        setProcessedClients(_processedData)  
    },[clients])

    const Logout = ()=>{
        get(LOGIN_ENDPOINTS.logout()).then(r =>{
            console.log(r.message)
            if(r.message === 'all is fine'){
                logout()
            }
        })
    }
    const handleDelete=(id, name)=>{
        setDeleteName(name)
        setDeleteId(id)
        activateDelete()
    }
    const udateFamily=()=>{
        let getGuest = get(USER_ENDPOINTS.getAll())
            getGuest.then((response) => {
                setClients(response.data);
            })
    }
    const handleDeleteFamily=()=>{
        let _deleteClientPromise=adelete(USER_ENDPOINTS.deleteFamily(deleteId))
        _deleteClientPromise
        .then(response=>{
            udateFamily()
        })
        .catch(err=>{
            console.log(err)
        })
        .finally(()=>{
            desactivateDelete()
        })
    }
    return(
        <>
            <div className='bg-tertiary min-h-screen flex flex-col'>
                <header className='w-full flex justify-between h-fit bg-white'>
                    <section className="w-fit h-24 my-auto ml-8 flex"><BottomAdd className="h-1/3 w-auto m-auto cursor-pointer" onClick={activateModal}/></section>
                    <section className="flex">
                        <MusicFile className="w-14 h-auto cursor-pointer" onClick={activateMusic}/>
                    </section>
                    <section className="w-fit h-24 my-auto mr-8 flex"><LogoutSvg className="h-1/3 w-auto m-auto cursor-pointer" onClick={Logout}/></section>
                </header>
                <main className='flex flex-col justify-center items-center my-auto h-fit w-full'>
                    <Table head={ESTranslation.TABLE_HEAD.users_page} columns="10% 50% 15% 25%" body={processedClients} error={fetchedClientsErrors} className='w-11/12 bg-white mx-auto grid text-center py-4 rounded-t-2xl'/>
                </main>
                <footer className='w-full mt-auto'>
                    <span className='flex flex-col justify-center items-center'>
                        <p className='text-secondary text-in'>©2024 Todos Los Derechos Reservados</p>
                    </span>
                </footer>
            </div>
            <ModalBackground isActive={isActiveModal} closeByBackground={desactivateModal}>
                <ModalSearcher title={"Invitacion"} closeByButton={desactivateModal} setClients={setClients} udateFamily={udateFamily}/>
            </ModalBackground>
            <ModalBackground isActive={isActiveDelete} closeByBackground={desactivateDelete}>
                <ModalAlert title={"¡Atención!"} message={`¿Estas seguro/a que deseas ELIMINAR la familia "${deleteName}"?`} closeByButton={desactivateDelete} handleSave={handleDeleteFamily} handleCancelation={desactivateDelete}/>
            </ModalBackground>
            <ModalBackground isActive={isActiveMusic} closeByBackground={desactivateMusic}>
                <ModalMusicView closeByButton={desactivateMusic} handleCancelation={desactivateMusic}/>
            </ModalBackground>
        </>
    )
}
export default UserHome