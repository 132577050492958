export const PORT=5893
export const ROOT='https://api.lingydaniel.maikdevs.com'
// export const ROOT='http://localhost:2024'


export const USER_ENDPOINTS={
    getAll:()=>`${ROOT}/api/guest`,
    getOne:(id)=>`${ROOT}/api/guest/${id}`,
    getOneFamily:(id)=>`${ROOT}/api/guest/user/family/${id}`,
    deleteFamily:(id)=>`${ROOT}/api/guest/user/family/${id}`,
    deleteGuest:(id)=>`${ROOT}/api/guest/user/guest/${id}`,
    create:()=>`${ROOT}/api/guest`,
    AddGuest:(id)=>`${ROOT}/api/guest/${id}`,
    updateStatus:(id)=>`${ROOT}/api/guest/status/guest/${id}`,
    updateFamilyUser:(id)=>`${ROOT}/api/guest/user/family/${id}`,
    updateGuestUser:(id)=>`${ROOT}/api/guest/user/guest/${id}`,
    updateMessage:(id)=>`${ROOT}/api/guest/message/${id}`,
    createMusic:()=>`${ROOT}/api/music`,
    getAllMusic:()=>`${ROOT}/api/music`,
}
export const LOGIN_ENDPOINTS={
    login:()=>`${ROOT}/api/signin`,
    logout:()=>`${ROOT}/api/logout`,
}