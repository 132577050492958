import React from 'react'

const CancelButton=({handle})=>{
    return (
        <button onClick={handle}  className='mt-2 mb-6 text-primary font-gideonRoman text-sm md:text-base lg:text-lg'>
            Cerrar
        </button>
    )
}

const Modal = ({title,message,handleCancelation}) => {
    const preventBubbling=(e)=>e.stopPropagation()
    return (
        <div className='m-auto w-10/12 md:w-7/12 lg:w-5/12 h-fit bg-white rounded-3xl' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-gideonRoman text-base mt-6 text-textColor md:text-lg lg:text-xl'>Zelle</h3>
                <p className='font-gideonRoman text-center text-sm px-4 mb-6 mt-4 text-textColor  md:text-base lg:text-lg'>Lingrea@gmail.com</p>
                <h3 className='w-fit m-auto font-gideonRoman text-base mt- text-textColor md:text-lg lg:text-xl'>Pago Movil</h3>
                <p className='font-gideonRoman text-center text-sm px-4 mb-6 mt-4 text-textColor  md:text-base lg:text-lg'>28.020.082<br/>0414-5720067<br/>Venezuela</p>
            </div>
            <div className='flex justify-center w-9/12 m-auto border-t-[1px] border-textColor border-opacity-50'>
                <CancelButton handle={handleCancelation}/>
            </div>
        </div>
    )
}

export default Modal