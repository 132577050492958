import React from 'react'

const SaveButton=({handle})=>{
    return (
        <button onClick={handle}  className='mt-2 mb-6 text-primary font-gideonRoman text-sm md:text-base lg:text-lg'>
            Guardar
        </button>
    )
}

const ModalMusic = ({handleSave, handleText, $name}) => {
    const preventBubbling=(e)=>e.stopPropagation()
    return (
        <div className='m-auto w-10/12  md:w-7/12 lg:w-5/12 h-fit bg-white rounded-3xl' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
                <h3 className='w-fit m-auto font-gideonRoman text-sm mt-6 text-textColor md:text-lg lg:text-xl'>¿Cúal canción te gustaría oir este día?</h3>
                <input type="text" ref={$name} className='font-gideonRoman text-sm px-4 py-2 mb-6 mt-4 text-textColor bg-black bg-opacity-10 w-10/12 m-auto rounded-lg md:text-base lg:text-lg' onChange={(e)=>{handleText(e.target.value)}} />
            </div>
            <div className='flex justify-center w-9/12 m-auto border-t-[1px] border-textColor border-opacity-50'>
                <SaveButton handle={handleSave}/>
            </div>
        </div>
    )
}

export default ModalMusic