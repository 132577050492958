import React from 'react'
import put from '../../helpers/put'
import { USER_ENDPOINTS } from '../../services/api.config'

const SaveButton=({handle})=>{
    return (
        <button onClick={handle}  className='mt-2 mb-6 text-primary font-gideonRoman text-sm md:text-base lg:text-lg'>
            cerrar
        </button>
    )
}


const ModalColor = ({link, data, handleCancelation,updata}) => {
    const preventBubbling=(e)=>e.stopPropagation()
    return (
        <div className='m-auto w-10/12 md:w-7/12 lg:w-5/12 h-fit bg-white rounded-3xl' onClick={(e)=>preventBubbling(e)}>
            <div className='flex w-full flex-col justify-center'>
            {/* <h3 className='w-fit m-auto font-gideonRoman text-base mt-6 text-textColor text-center px-10'></h3> */}
            <p className=" text-center text-primary font-greatVibes mt-6 md:mt-10 text-3xl md:text-4xl lg:text-5xl xl:text-7xl ">Para Ellas:</p>
            {/* <h3 className='w-fit m-auto font-gideonRoman text-base mt-6 text-textColor text-center px-10'>¿Qué colores te recomendamos usar y cuales no?</h3> */}
                <p className='mx-4 font-gideonRoman text-sm  text-textColor pr-4 pl-8 pt-4'>Colores recomendados:</p>
                <div className='flex px-10 pt-4 justify-around'>
                    <div className="flex w-10 h-10 rounded-full bg-[#b6cfb6] border-[#e8e8e8] border-4"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#ffaea5] border-[#e8e8e8] border-4"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#ecd5e3] border-[#e8e8e8] border-4"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#ffc8a2] border-[#e8e8e8] border-4"></div>
                </div>
                <div className='flex px-10 pt-4 justify-around'>
                    <div className="flex w-10 h-10 rounded-full bg-[#abdee6] border-[#e8e8e8] border-4"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#cbaacb] border-[#e8e8e8] border-4"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#f6eac2] border-[#e8e8e8] border-4"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#f3b0c3] border-[#e8e8e8] border-4"></div>
                </div>
                <p className='mx-4 font-gideonRoman text-sm  text-textColor pr-4 pl-8 pt-6'>Colores no recomendados:</p>
                <div className='flex px-10 pt-4  justify-around mb-8'>
                    <div className="flex w-10 h-10 rounded-full bg-[#ffffff] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#000000] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#ff2828] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
                    <div className="flex w-10 h-10 rounded-full bg-[#14035e] border-[#e8e8e8] border-4 before:content-[''] before:w-full before:h-1 before:border-b-4 before:border-[#e8e8e8] before:flex before:m-auto before:rotate-45"></div>
                </div>
            </div>
            <div className='flex justify-center w-9/12 m-auto border-t-[1px] border-textColor border-opacity-50'>
                <SaveButton handle={handleCancelation}/>
            </div>
        </div>
    )
}

export default ModalColor