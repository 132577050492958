import React, {  useEffect, useRef, useState,  } from "react";
import Time from "../../components/time";
import {ReactComponent as Man } from "../../assets/images/man.svg"
import {ReactComponent as Woman } from "../../assets/images/woman.svg"
import {ReactComponent as Arrow } from "../../assets/images/arrow.svg"
import {ReactComponent as Music } from "../../assets/images/music.svg"
import {ReactComponent as Location } from "../../assets/images/location.svg"
import {ReactComponent as Gift } from "../../assets/images/gift.svg"
import {ReactComponent as LocoG } from "../../assets/images/logoG.svg"
import {ReactComponent as OndaBg } from "../../assets/images/bg/ondaBg.svg"
import {ReactComponent as Onda1 } from "../../assets/images/bg/onda.svg"
import {ReactComponent as Onda2 } from "../../assets/images/bg/onda2.svg"
import {ReactComponent as Onda3 } from "../../assets/images/bg/onda3.svg"
import {ReactComponent as Onda4 } from "../../assets/images/bg/onda4.svg"
import {ReactComponent as Line } from "../../assets/images/line.svg"
import {ReactComponent as Line2 } from "../../assets/images/line2.svg"
import {ReactComponent as Love } from "../../assets/images/love.svg"
import grammas from "../../assets/images/grammas.png"
import maik from "../../assets/images/maik.png"
import Itinerary from "../../components/itinerary";
import ModalBackground from "../../components/modal/modal_background";
import Modal from "../../components/modal/modal";
import useModal from "../../hooks/useModal"
import ModalMusic from "../../components/modal/modal_music";
import AnimationHome from "../../components/animation";
import { USER_ENDPOINTS } from "../../services/api.config";
import post from "../../helpers/post";
import { useLocation } from "react-router-dom";
import get from "../../helpers/get";
import ModalConfig from "../../components/modal/modal_config";
import ModalColor from "../../components/modal/modal_color";


const preprocessTableBodyData=(data)=>{
  console.log(data)
  data=data || []
  const actionButtons=(id,status,name)=>{
    var use = false
    if(id.length > 0){
        use = true
    }

    let check = false
    if(status===2){
      check = true
    }
    console.log(check)
    return (
      <div className="flex">
        <div className="flex w-10/12">
            <p className=" mx-4 font-poppins text-sm font-gideonRoman md:text-base lg:text-lg">{name}</p>
        </div>
        <div className="flex justify-center">
          <input id={"name"+id} value={id} type="checkbox" class=" hidden peer consulta12" defaultChecked={check}/>
          <label for={"name"+id} className="w-5 h-5 flex bg-[#dadada] rounded-full  peer-checked:before:opacity-100 before:pl-1 before:opacity-0 before:content-[''] before:w-[10px] before:h-[5px] before:border-b-2 before:border-l-2 before:border-primary before:flex before:m-auto before:mt-[7px] before:rotate-[315deg]"></label>
          {/* <label for={"name"+id} className="w-5 h-5 flex bg-[#FFD5D5] rounded-full peer-checked:bg-primary peer-checked:before:opacity-100 before:pl-1 before:opacity-0 before:content-[''] before:w-[10px] before:h-[5px] before:border-b-2 before:border-l-2 before:border-white before:flex before:m-auto before:mt-[7px] before:rotate-[315deg]"></label> */}
        </div>
    </div>
    )
  }
  const htmlName=(id,pasta,salsa,status,name)=>{
    let pasta1 = false
    let pasta2 = false
    let salsa1 = false
    let  salsa2 = false
    let salsa3 = false
    let nino = false
    if(pasta===1){
      pasta1 = true
    }
    if(pasta===2){
      pasta2 = true
    }
    if(salsa===1){
      salsa1 = true
    }
    if(salsa===2){
      salsa2 = true
    }
    if(salsa===3){
      salsa3 = true
    }
    let check = false
    let check2 = false
    if(status===2){
      check = true
    }
    if(status===3){
      check2 = true
    }
    var use = false
    if(id.length > 0){
        use = true
    }
    if(name.includes('niño')|| name.includes('kids')){
      nino=true
    }
    return (
        <div className="relative">
          <div className="flex">
            <div className="flex w-10/12">
                <p className=" mx-4 font-poppins text-sm font-gideonRoman md:text-base lg:text-lg">{name}</p>
            </div>
          </div>
          <div className=" overflow-hidden">
            <input id={"nombre"+id} value={id} type="radio" name={"use"+id} class=" hidden peer/no consulta222" defaultChecked={check2}/>
            <label for={"nombre"+id} className=" top-[-2px] right-[25%] absolute w-5 h-5 flex bg-[#dadada] rounded-full  peer-checked/no:before:bg-[#fd8787] before:w-[13px] before:h-[2px]  before:bg-[#b8b8b8] before:m-auto before:mt-[9px] before:rotate-[315deg] peer-checked/no:after:bg-[#fd8787] after:w-[13px] after:h-[2px]  after:bg-[#b8b8b8] after:m-auto after:mt-[9px] after:rotate-[-315deg] before:absolute before:left-[3px]"></label>
            <input id={"name"+id} value={id} type="radio"  name={"use"+id} class=" hidden peer consulta12" defaultChecked={check}/>
            <label for={"name"+id} className=" top-[-2px] right-[15%] absolute w-5 h-5 flex bg-[#dadada] rounded-full  peer-checked:before:border-primary before:pl-1 before:content-[''] before:w-[10px] before:h-[5px] before:border-b-2 before:border-l-2 before:border-[#b8b8b8] before:flex before:m-auto before:mt-[7px] before:rotate-[315deg]"></label>
            {nino? <></>:<div className="flex justify-between peer-checked:mt-0 mt-[-5rem]"  style={{transitionDuration: '1000ms', transitionProperty: 'all'}}>
              <select id="small" class="block w-5/12 p-2 text-sm text-textColor border-b border-tertiary  bg-gray-50 focus:outline-none appearance-none text-center font-gideonRoman consulta13 mt-2">
                <option className=" hidden" value="0">Tipo de Pasta</option>
                <option value="1" selected={pasta1}>Corta</option>
                <option value="2" selected={pasta2}>Larga</option>
              </select>
              <select id="small" class="block w-5/12 p-2 text-sm text-textColor border-b border-tertiary  bg-gray-50 focus:outline-none appearance-none text-center font-gideonRoman consulta14 mt-2">
                <option className=" hidden" value="0">Tipo de Salsa</option>
                <option value="1" selected={salsa1}>Alfredo</option>
                <option value="2" selected={salsa2}>Pesto</option>
                <option value="3" selected={salsa3}>Boloñesa</option>
              </select>
            </div>
          }
          </div>
          
        </div>
      )
  }
  let _processedData=[...data].map(row=>{
      let {id,name, status,pasta,salsa}=row
          let _newData={
              // actions:actionButtons(id,status,name),
              Name: htmlName(id,pasta,salsa,status,name),
          }
          return _newData
  })
  return _processedData
      
}

const Home = () => {
  let [isActiveModal,activateModal,desactivateModal]=useModal(false)
  let [isActiveModalMusic,activateModalMusic,desactivateModalMusic]=useModal(false)
  let [isActiveModalConfir,activateModalConfir,desactivateModalConfir]=useModal(false)
  let [isActiveModalColor,activateModalColor,desactivateModalColor]=useModal(false)
  let [title, isTitle] = useState('')
  let [message, isMessage] = useState('')
  let [music, isMusic] = useState('')
  let [divMainClassName, isDivMainClassName] = useState("h-[100vh] overflow-hidden")
  let [logoClassName, IsLogoClassName] = useState('w-3/12 mx-auto mb-40 mt-auto h-auto')
  let [timeClassName, isTimeClassName] = useState('mt-24')
  let [sizeClassName, isSizeClassName] = useState('150vw')
  let [link, setLink] = useState()
  let [guest, setGuest] = useState()
  let [id, setId] = useState()
  let [black, setBlack] = useState(false)
  let [processedClients,setProcessedClients]=useState(null)
  let $name = useRef()
  const location = useLocation();
  
  const GuestOne = ()=>{
    const queryGuest = new URLSearchParams(location.search).get('link')
    if(queryGuest){
      get(USER_ENDPOINTS.getOne(queryGuest)).then(r =>{
        if(r.message === 'all is fine'){
          setBlack(true)
          setId(r.data.id)
          setGuest(r.data.guest)
          setLink(queryGuest)
        }
        if(r.message==='error does not exist'){
          setBlack(false)
        }
      })
    }
  }

  let m1 = 'Si, toma tus previsiones para que llegues a tiempo, sólo se esperará a la novia para iniciar la ceremonia.'
  let m2 = 'La boda durará hasta las 2 de la mañana aproximadamente.'
  let m3 = 'Solo podrán asistir los niños que fueron invitados específicamente.'
  let m4 = 'No, sólo las personas invitadas por los novios podrán asistir.'
  let m5 = 'Queremos que todos disfrutemos  sin tener que preocuparnos por nada así que si planeas tomar lleva chofer designado o entrega las llaves del carro.'

  const question = (Title, Message) =>{
    isTitle(Title)
    isMessage(Message)
    activateModal()
  }
  const handleSave = () => {
    if(music.length > 0 ){
      let _data={
        name : music,
      }
      let _createClientPromise=post(USER_ENDPOINTS.createMusic(),_data)
      _createClientPromise.then(response=>{
          if(response.message==="all is fine"){
            desactivateModalMusic()
            $name.current.value = ''
          }
      })
    }
  }
  useEffect(()=>{
    let _processedData=preprocessTableBodyData(guest)
    setProcessedClients(_processedData) 
  },[guest])
  useEffect(() => {
    GuestOne()
  }, []);
  return (
    <>
    {/* {black? */}
      <div className={`${divMainClassName}`}>
        <AnimationHome isDivMainClassName={isDivMainClassName} IsLogoClassName={IsLogoClassName} isTimeClassName={isTimeClassName} isSizeClassName={isSizeClassName}/>
        <section className="bg-[url('/src/assets/images/bg/bg-main.jpg')] bg-cover bg-center bg-top w-full lg:h-[90vh]  md:h-[60vh] h-[40vh] flex content-between justify-between flex-col mb-[-1px]"  style={{transitionDuration: '1300ms', transitionProperty: 'all', backgroundSize : sizeClassName}}>
          <LocoG className={logoClassName} style={{transitionDuration: '1300ms', transitionProperty: 'all'}}/>
          <div className="w-full mb-[-1px] overflow-hidden h-fit flex">
            <OndaBg className="w-[105%] ml-[-2%]  h-auto mt-auto mb-0"/>
          </div>
        </section>
        <div className={timeClassName+` pt-2 md:pt-6`} style={{transitionDuration: '1300ms', transitionProperty: 'all', backgroundColor:'#fff'}}>
          <Time/>
          <div className="w-full md:mt-4 mt-1 overflow-hidden">
            <Line className="md:w-[110%] md:ml-[-1%] w-[140%] ml-[-15%]"/>
          </div>
          <Love className="md:w-12 md:h-12 md:mt-0 lg:mt-4 w-8 h-8 m-auto mt-[-6vw] corazon"/>
        </div>
        <div className="w-full overflow-hidden mt-2 md:mt-6 lg:mt-10 h-fit">
          <Onda1 className="w-[112%] ml-[-5%] h-auto"/>
        </div>
        <section className="w-full m-auto bg-tertiary p-4 md:p-8 md:pb-4 pb-2 mt-[-1px] mb-[-1px]  h-fit">
          <p className=" text-center text-primary font-greatVibes mb-6 md:mb-10 text-3xl md:text-4xl lg:text-5xl xl:text-7xl ">Nos Casamos</p>
          <p className=" text-center text-textColor font-gideonRoman my-6 text-sm w-5/6 md:w-9/12 lg:w-8/12 md:text-base lg:text-lg m-auto xl:text-2xl xl:w-7/12">Celebrar nuestra boda representa uno de los momentos más especiales de nuestras vidas. 
          Que compartas esta alegría con nosotros será una bendición. </p>
        </section>
        <div className="w-full mt-auto mb-0 overflow-hidden md:mb-4 lg:mb-8">
            <Onda2 className="w-[110%] ml-[-7%] h-auto mt-auto mb-0"/>
        </div>
        <section className="mt-4 flex flex-col">
          <h2 className="text-center text-primary font-greatVibes mb-6 text-3xl md:text-4xl lg:text-5xl m-auto efectop">Dress Code</h2>
          <section className="flex w-9/12 m-auto mt-10 flex-col md:mt-12 lg:mt-14 efectop">
            <div className="flex">
              <div  className="m-auto w-1/2"><Man className="h-28 md:h-36 lg:h-44 w-auto m-auto"/></div>
              <div  className="m-auto w-1/2"><Woman className="h-28 md:h-36 lg:h-44 w-auto m-auto"/></div>
            </div>
            <div className="flex m-auto w-full mt-4 md:mt-6 lg:mt-8">
              <div className="m-auto w-1/2"><p className="w-fit mx-auto font-gideonRoman text-primary text-sm  md:text-base lg:text-lg efectop">Traje Formal</p></div>
              <div className="m-auto w-1/2"><p  className="w-fit mx-auto font-gideonRoman text-primary text-sm  md:text-base lg:text-lg efectop">Vestido Largo</p></div>
            </div>
            <div className="mx-auto mt-8 md:mt-10 lg:mt-12 w-full flex justify-center efectop">
              <Arrow className="w-10 h-auto rotate-180 fill-primary"/>
              <p  className="w-fit mx-4 font-gideonRoman text-primary text-sm md:text-base lg:text-lg border-b-[1px]" onClick={activateModalColor}>¿Que colores usar?</p>
              <Arrow className="w-10 h-auto fill-primary"/>
            </div>
          </section>
        </section>
        <section className="mb-10 md:mb-14 lg:mb-18">
          <div className="w-full mt-2 md:mt-8 lg:mt-12 overflow-hidden efectop">
            <Line2 className="w-[135%] ml-[-15%] md:w-[110%] md:ml-[-4%]"/>
          </div>
          <p className="text-center text-textColor font-gideonRoman mt-1 text-sm w-7/12 m-auto md:text-base lg:text-lg md:mt-4 lg:mt-8 efectop">¿Cuáles canciones no deberían faltar en este noche especial?</p>
          <div className="flex w-fit mx-auto mt-4 cursor-pointer md:mt-10 lg:mt-14 efectop" href="#/" onClick={activateModalMusic}>
            <div className="my-auto w-12 h-12 md:w-14 md:h-14 lg:w-16 lg:h-16 flex bg-tertiary rounded-full mr-[-1rem] box-shadow-music z-10">
              <Music className=" w-6/12 h-3/6 m-auto fill-primary "/>
            </div>
            <p className="w-fit my-auto font-gideonRoman text-primary text-sm bg-tertiary px-4 pl-5 py-2 rounded-tr-full rounded-br-full md:text-base lg:text-lg md:px-5 md:py-3 md:pl-7">Sugerir canciones</p>
          </div>
        </section>
        <section className="flex relative efectom">
        <div className="w-full overflow-hidden h-fit flex absolute top-[-1px]">
            <OndaBg className="w-[105%] ml-[-2%]  h-auto mt-auto mb-0 rotate-180"/>
          </div>
          <div className="bg-[url('/src/assets/images/img/1.jpg')] bg-cover bg-center w-1/4 h-[50vw]"></div>
          <div className="bg-[url('/src/assets/images/img/2.jpg')] bg-cover bg-center w-1/4 h-[50vw]"></div>
          <div className="bg-[url('/src/assets/images/img/3.jpg')] bg-cover bg-center w-1/4 h-[50vw]"></div>
          <div className="bg-[url('/src/assets/images/img/4.jpg')] bg-cover bg-center w-1/4 h-[50vw]"></div>
          
          <div className="w-full overflow-hidden absolute bottom-[-10px]">
            <Onda3 className="w-[112%] ml-[-5%] h-auto fill-tertiary"/>
          </div>
        </section>
        <section className="w-full m-auto bg-tertiary p-4 md:p-8 md:pb-4 pb-2 mt-[-1px] mb-[-1px]  h-fit">
          <p className=" text-center text-textColor font-greatVibes text-2xl md:text-3xl lg:text-4xl xl:text-6xl efectop">25 de Octubre del 2024</p>
          <p className=" text-center text-textColor font-gideonRoman text-sm w-5/6 md:w-9/12 lg:w-8/12 md:text-base lg:text-lg m-auto xl:text-2xl xl:w-7/12 efectop">Hora: 4:30pm</p>
          <div className="w-full flex efectop">
            <a className="mx-auto mt-6 flex md:mb-0" target="_blank"  href="https://maps.app.goo.gl/rzL7P4FWWPxA8RD67">
              <Location className="w-10 h-10 fill-[#777777] md:h-12 md:w-12 lg:h-14 lg:w-14"/>
              <div className="ml-1 my-auto">
                <p className="text-center text-textColor font-gideonRoman text-base w-fit md:text-lg lg:text-xl mx-auto">Hacienda Agua Viva</p>
                <p className="text-center text-textColor font-gideonRoman text-xs w-fit md:text-sm lg:text-base mx-auto border-b-[1px] border-textColor">Ver Aquí</p>
              </div>
              <Location className="w-10 h-10 fill-tertiary md:h-12 md:w-12 lg:h-14 lg:w-14"/>
            </a>
          </div>
        </section>
          <div className="w-full mt-auto mb-0 overflow-hidden bg-secondary">
              <Onda1 className="w-[110%] ml-[-7%] h-auto mt-[-1px] rotate-180"/>
          </div>
        <section className="w-full m-auto bg-secondary p-4 pb-2 mt-[-1px] mb-[-1px]">
          <p className=" text-center text-primary font-greatVibes mb-6 md:mb-10 text-3xl md:text-4xl lg:text-5xl efectop">Regalos</p>
          <p className=" text-center text-textColor font-gideonRoman mt-6 mb-3 md:mb-4 text-sm w-5/6 md:w-9/12 lg:w-8/12 m-auto md:text-base lg:text-lg efectop">Lo más importante es que tu regalo sea dado con afecto y acorde a tus posibilidades financieras. Agradeceríamos que este sea en efectivo.</p>
          <Gift className="w-20 h-auto m-auto md:w-24 lg:w-28 efectop"/>
          <div className="mx-auto w-full flex justify-center mb-6 md:mt-2 md:mb-10 efectop">
            <Arrow className="w-10 h-auto rotate-180 fill-[#767289] my-auto"/>
            <p className="mx-4 text-center text-[#767289] border-b-[1px] w-fit font-gideonRoman md:text-base lg:text-lg md:border-b-[2px]" onClick={()=>{question('Muchas Graciasvv', `Zelle: <br/>`)}}><a>Más información</a></p>
            <Arrow className="w-10 h-auto fill-[#767289] my-auto"/>
          </div>
        </section>
        <section className="bg-[url('/src/assets/images/bg/bg-end.jpg')] bg-cover bg-center w-full flex flex-col bg-opacity-70  h-[150vw] md:h-[90vw]">
          <div className="w-full mt-[-1px] overflow-hidden ">
              <Onda4 className="w-[110%] ml-[-7%] h-auto mt-auto mb-0"/>
          </div>
          <div className="mt-auto w-full bg-gradient-to-t from-white flex h-1/4 md:h-2/6">
            
          </div>
        </section>
        <section className="w-full flex flex-col bg-opacity-70">
          <p className="text-center text-textColor font-gideonRoman text-sm w-9/12 mx-auto md:text-base lg:text-lg efectop">
          Estamos preparando cada detalle junto a nuestra familia y queremos disfrutar cada momento contigo. Por favor  confirma tu asistencia
          </p>
          <p className="w-fit mx-auto font-gideonRoman text-white text-sm bg-primary px-12 py-3 rounded-full my-8 cursor-pointer md:text-base lg:text-lg md:my-12 lg:my-14 efectom" onClick={activateModalConfir}>Confirma tu asistencia aquí</p>
          <a href="https://www.instagram.com/maik_dev?igsh=ZjI1amx1NmxlajNt" target="_blank" className="opacity-80 text-xs text-textColor w-fit mx-auto my-2">Desarrollado por MAIK © 2024</a>
        </section>


        <ModalBackground isActive={isActiveModal} closeByBackground={desactivateModal}>
          <Modal title={title} message={message} closeByButton={desactivateModal} handleCancelation={desactivateModal}/>
        </ModalBackground>
        <ModalBackground isActive={isActiveModalMusic} closeByBackground={desactivateModalMusic}>
          <ModalMusic handleSave={handleSave} handleText={isMusic} $name={$name}/>
        </ModalBackground>
        <ModalBackground isActive={isActiveModalConfir} closeByBackground={desactivateModalConfir}>
          <ModalConfig link={id} data={processedClients} handleCancelation={desactivateModalConfir} updata={GuestOne}/>
        </ModalBackground>
        <ModalBackground isActive={isActiveModalColor} closeByBackground={desactivateModalColor}>
          <ModalColor handleCancelation={desactivateModalColor} updata={GuestOne}/>
        </ModalBackground>
      </div>
    {/* :''} */}
    </>
    
  );
}

export default Home;

