import React, { useState } from 'react';
import post from "../../helpers/post";
import Input from '../../components/Input';
import passview from "../../assets/images/user/eye-hollow-dark.svg";
import passnotview from "../../assets/images/user/eye-slashed-hollow-dark.svg";
import { LOGIN_ENDPOINTS } from '../../services/api.config';
import { UserAuthContext } from '../../contexts/authContext';





const User = () => {
    const {login} = UserAuthContext();
    const [user, setUser ] = useState('');
    const [password, setPassword] = useState('')
    const [passwordView, setPasswordView] = useState('password')
    const [passwordError, setPasswordError] = useState(false)
    const [userError, setUserError] = useState(false)
    const [message, setMessage] = useState('')
    const [passViewImg, setPassViewImg] = useState(passnotview)

    const handleChange = (name, value) => {
        if(name === 'user'){
            if(value.length < 1){
                setUserError(true)
                setUser('')
            }
            if(value.length >= 1){
                setUser(value)
                setUserError(false)
            }
        }
        if(name === 'Password'){
            if(value.length < 6){
                setPasswordError(true)
                setPassword('')
            }
            if(value.length >= 6){
                setPassword(value)
                setPasswordError(false)
            }
        }
    }
    const handleSubmintsingin = (e) => {
        e.preventDefault()
        setMessage('')
        let account = {user, password}
        if(account){
            if(user.length >= 1){
                if(password.length >= 6){
                    let _data = {
                        "username": user,
                        "password": password
                    }
                    let _login=post(LOGIN_ENDPOINTS.login(),_data)
                    _login
                    .then(response=>{
                        console.log(response)
                        if(response.message === 'active session' || response.message === 'all fine'){
                            login();
                        }
                        if(response.message === 'Incorrect user or pasword'){
                            setMessage('Usuario o Contraseña incorrecto')
                        }
                    })
                    setMessage('')
                    return
                }
                setMessage('Contraseña debe contener minimo 8 caracteres')
                setPasswordError(true)
                return
            }
            setUserError(true)
            setMessage('Usuario vacío')
            return
        }
    }
    const PasswordView = () =>{
        if(passwordView === 'password'){
            setPasswordView('text')
            setPassViewImg(passview)
        }
        if(passwordView === 'text'){
            setPasswordView('password')
            setPassViewImg(passnotview)
        }
    }
    return(
        <div className='bg-tertiary min-h-screen flex flex-col'>
            <header className='h-28 w-full flex justify-center'>
                <span className="flex justify-center items-center h-full">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 499.65 116.83" className='w-32'><path d="M42.3,137.42H18.43a2.27,2.27,0,0,1-2.26-2.26V22.85a2.26,2.26,0,0,1,2.26-2.26H49.25a2.26,2.26,0,0,1,2.17,1.61L67.69,75.87a2.26,2.26,0,0,0,4.33,0L88.29,22.2a2.26,2.26,0,0,1,2.17-1.61h30.82a2.26,2.26,0,0,1,2.26,2.26V135.16a2.27,2.27,0,0,1-2.26,2.26H97.59a2.26,2.26,0,0,1-2.26-2.26V78.7a2.26,2.26,0,0,0-4.43-.64l-9.65,32.45a2.24,2.24,0,0,1-2.16,1.61H60.63a2.25,2.25,0,0,1-2.17-1.62L49,78.5a2.26,2.26,0,0,0-4.43.64v56A2.26,2.26,0,0,1,42.3,137.42Z" transform="translate(-16.17 -20.58)" fill="#fff"/><path d="M236.22,46.61h25.87a2.26,2.26,0,0,1,2.26,2.26v86.29a2.26,2.26,0,0,1-2.26,2.26H235.68a2.26,2.26,0,0,1-2.26-2.26h0a2.25,2.25,0,0,0-3.76-1.68q-4.31,3.93-12.26,3.94h-3.27q-24.21,0-24.2-22.57V69.18q0-22.56,24.2-22.57h3.27q8.43,0,12.71,4.22a2.26,2.26,0,0,0,3.85-1.6v-.36A2.26,2.26,0,0,1,236.22,46.61Zm-10.81,68.06h3.09q4.92,0,4.92-5.46V74.82q0-5.46-4.92-5.46h-3.09c-3,0-4.55,1.34-4.55,4v37.31Q220.86,114.68,225.41,114.67Z" transform="translate(-16.17 -20.58)" fill="#fff"/><rect x="314.38" width="32.03" height="21.84" rx="2.26" fill="#fff"/><rect x="314.92" y="26.03" width="30.94" height="90.8" rx="2.26" fill="#fff"/><path d="M428.78,135.16V22.85A2.26,2.26,0,0,1,431,20.59h27.32a2.26,2.26,0,0,1,2.26,2.26V135.16a2.26,2.26,0,0,1-2.26,2.26H431A2.26,2.26,0,0,1,428.78,135.16Zm65.46-56.6,21.43,55.79a2.26,2.26,0,0,1-2.11,3.07H484.22a2.27,2.27,0,0,1-2.12-1.49l-21-57.41a2.26,2.26,0,0,1,0-1.58l20.81-54.89A2.25,2.25,0,0,1,484,20.59h29.32a2.26,2.26,0,0,1,2.1,3.1L494.25,76.91A2.29,2.29,0,0,0,494.24,78.56Z" transform="translate(-16.17 -20.58)" fill="#fff"/></svg>
                </span>
            </header>
            <main className='flex flex-col justify-center items-center my-auto h-fit'>
                <section className="rounded-3xl bg-current bg-white p-4 w-10/12 md:w-4/12">
                    <form  id="LogIn" onSubmit={handleSubmintsingin}>
                        <span className="flex">
                            <svg  className='w-20 m-auto' viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M61.8571 57.6506C65.1781 53.6549 67.4879 48.918 68.5911 43.8408C69.6943 38.7636 69.5584 33.4953 68.1949 28.4817C66.8314 23.4681 64.2805 18.8567 60.7578 15.0374C57.2352 11.2182 52.8445 8.30362 47.9572 6.54018C43.07 4.77673 37.8298 4.21633 32.6802 4.90638C27.5305 5.59643 22.6228 7.51663 18.3722 10.5045C14.1216 13.4925 10.6531 17.4602 8.26023 22.0721C5.86736 26.6839 4.62046 31.8043 4.62501 37C4.62676 44.5531 7.28842 51.8642 12.143 57.6506L12.0967 57.6899C12.2586 57.8842 12.4436 58.0507 12.6101 58.2426C12.8182 58.4808 13.0425 58.7051 13.2576 58.9364C13.9051 59.6394 14.5711 60.3146 15.2695 60.9482C15.4822 61.1425 15.7019 61.3229 15.917 61.5079C16.657 62.1461 17.4178 62.752 18.2063 63.3162C18.3081 63.3856 18.4006 63.4758 18.5023 63.5475V63.5197C23.9184 67.3311 30.3796 69.3767 37.0023 69.3767C43.6251 69.3767 50.0862 67.3311 55.5023 63.5197V63.5475C55.6041 63.4758 55.6943 63.3856 55.7983 63.3162C56.5846 62.7497 57.3477 62.1461 58.0877 61.5079C58.3028 61.3229 58.5225 61.1402 58.7352 60.9482C59.4336 60.3123 60.0996 59.6394 60.7471 58.9364C60.9621 58.7051 61.1841 58.4808 61.3946 58.2426C61.5588 58.0507 61.7461 57.8842 61.908 57.6876L61.8571 57.6506ZM37 18.5C39.0582 18.5 41.0701 19.1103 42.7814 20.2538C44.4927 21.3972 45.8265 23.0225 46.6141 24.924C47.4018 26.8254 47.6078 28.9178 47.2063 30.9364C46.8048 32.955 45.8137 34.8092 44.3583 36.2646C42.903 37.7199 41.0488 38.711 39.0302 39.1125C37.0116 39.5141 34.9192 39.308 33.0177 38.5204C31.1162 37.7327 29.491 36.399 28.3475 34.6877C27.2041 32.9764 26.5938 30.9644 26.5938 28.9062C26.5938 26.1463 27.6901 23.4995 29.6417 21.5479C31.5932 19.5964 34.2401 18.5 37 18.5ZM18.5162 57.6506C18.5563 54.6142 19.7903 51.7157 21.9511 49.5822C24.1119 47.4486 27.0259 46.2516 30.0625 46.25H43.9375C46.9742 46.2516 49.8881 47.4486 52.0489 49.5822C54.2098 51.7157 55.4437 54.6142 55.4838 57.6506C50.4122 62.2209 43.8271 64.7502 37 64.7502C30.1729 64.7502 23.5879 62.2209 18.5162 57.6506Z" fill="#3C3C3C"/>
                            </svg>
                        </span>
                        <span className="w-9/12 flex m-auto flex-col ">
                            <label htmlFor="User" className='mt-4 text-base  text-textColor'>Usuario</label>
                            <Input
                                attribute={{
                                    id : "user",
                                    name : "user",
                                    type : "text",
                                    placeholder : "Usuario"
                                }}
                                handleChange = {handleChange}
                                param = {userError}
                            />
                        </span>
                        <span className=" w-9/12 flex m-auto flex-col relative">
                            <label htmlFor="Password"  className='mt-6  text-base text-textColor'>Contraseña</label>
                            <Input
                                attribute={{
                                    id : "Password",
                                    name : "Password",
                                    type : passwordView,
                                    placeholder : "Contraseña"
                                }}
                                handleChange = {handleChange}
                                param = {passwordError}
                            />
                            <input type="checkbox" id="Eye" className='hidden' onChange={(e) => PasswordView(e.target.value)}/>
                            <label className="bg-center bg-contain absolute flex w-4 h-4 -right-5 bottom-3" htmlFor="Eye"  style={{ backgroundImage: `url(${passViewImg})` }} ></label>
                        </span>
                        <p className="w-8/12 mb-2 mt-2 text-center mx-auto text-alert text-sm">{message}</p>
                        <div className='h-0 border-solid border-t-[1px] border-primary w-56 mx-auto mt-10'></div>
                        <button className="h-fit flex mb-0 font-roboto-regular text-primary mx-auto relative text-lg mt-4" type="submit">
                            Entrar
                        </button>
                    </form>
                </section>
            </main>
            <footer className='w-full mt-auto'>
                <span className='flex flex-col justify-center items-center'>
                    <p className=' text-secondary text-in'>©2024 Todos Los Derechos Reservados</p>
                </span>
            </footer>
        </div>
    )
}

export default User